@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[id^="react-select-"] {
  @apply focus:outline-none focus:border-transparent focus:ring-0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.my-toggled-on .flowbite-switch-label {
  color: white; /* Set the text color to white for the toggled-on state */
}

.my-toggled-off .flowbite-switch-label {
  color: black; /* Set the text color to black for the toggled-off state */
}
